import { onAuthStateChanged } from 'firebase/auth'
import { useRouter } from 'next/router'
import { memo, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import userIdAtom from '../atoms/userIdAtom'
import { auth } from '../firebase'

const Index = memo(() => {
  
  const router = useRouter()
  const setUserId = useSetRecoilState(userIdAtom)

  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.email)
        router.push('/app')
      } else {
        setUserId(null)
        router.push('/home')
      }
    })
    return unsubscribe
  }, [])

  return <div className='loader' style={{position: 'absolute', width: '36px', height: '36px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} />

})

export default Index